/* eslint react/prop-types: 0 */
import {
  Box,
  Button,
  Center,
  Checkbox,
  Group,
  NumberInput,
  Paper,
  Select,
  Space,
  Stack,
  TextInput,
  Title
} from '@mantine/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from '@mantine/form';
import { generatePath } from 'react-router-dom';
import { useDebouncedField } from '../../../../forms/UpdateOnSubmitField';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { inviteStyleOptions, thankYouStyleOptions } from '../landing/LandingPageStyle';
import { useAccount } from '../../../../util/Hooks';
import { LinkPrefixEditor, OverrideDefaultFieldInput } from '../open/OpenInvitesCreate';
import { InviteStyle } from '../../../../../js/generated/enums/InviteStyle';
import { ThankYouStyle } from '../../../../../js/generated/enums/ThankYouStyle';
import { formatDateFromSource, formatDateToSource, formatNullableBooleanChoiceToSource } from '../formatUtil';
import { PreviewButton } from '../nav/CycleNavButton';
import { ComingSoonTooltip } from '../nav/ComingSoonTooltip';
import { PhaseSelection } from './PhaseSelection';
import NoticeAlert from '../../../../core/Alert/NoticeAlert';

export function ConfigEdit ({ cycles, template, onSubmit, onCancel, validating }) {
  const account = useAccount()

  const loading = !account.email
  const hasCycles = !!cycles?.length
  const previewRedirectLink = hasCycles ? generatePath(CyclePassRoute.CycleInvitesCreatePreview, { cycleId: cycles[0].toString() }) : null
  return (
        <>
            {!loading && !!hasCycles && (
                <InviteConfigEdit
                    cycles={cycles}
                    template={template}
                    account={account}
                    processing={validating}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    previewRedirectLink={previewRedirectLink}
                />
            )}
            {!loading && !hasCycles && (
                <>
                    <Space h='xxl' />
                    <NoticeAlert title='No Cycles'>
                        Must have at least one valid associated cycle to create an invite template!
                    </NoticeAlert>
                </>
            )}
        </>
  )
}

function InviteConfigEdit ({ cycles, template, account, processing, onSubmit, onCancel }) {
  console.debug('InviteConfigEdit updating', { template, cycles, account, processing })

  const form = useForm({
    name: 'invite-config-template-form',
    initialValues: {
      internalNote: template?.inviteConfig?.internalNote ?? '',
      landingPageHeader: template?.inviteConfig?.header ?? '',
      landingPageMessage: template?.inviteConfig?.message ?? '',
      linkPrefix: template?.inviteConfig?.linkPrefix ?? '',
      secondsUntilExpirationDate: template?.secondsUntilExpirationDate ? template.secondsUntilExpirationDate / secondsInDay : null,
      daysUntilDisplayDeadline: template?.daysUntilDisplayDeadline ?? 10,
      autoCloseByDate: formatDateFromSource(template?.inviteConfig?.expirationDate ?? null),
      landingPageStyle: (template?.inviteConfig?.style ?? InviteStyle.Default).toString(),
      thankYouPageStyle: (template?.inviteConfig?.thankYouStyle ?? ThankYouStyle.ThankYouForYourTime).toString(),
      collectDemographics: template?.inviteConfig?.collectDemographics ?? null,
      emailOnSubmit: template?.inviteConfig?.emailOnSubmit ?? false,
      proctored: template?.inviteConfig?.proctored ?? null,
      customApplicantFlagName: template?.inviteConfig?.applicantFlagTemplate?.defaultTitle ?? '',
      customApplicantFlagDescription: template?.inviteConfig?.applicantFlagTemplate?.displayClass?.defaultText ?? '',
      customApplicantFlagIcon: template?.inviteConfig?.applicantFlagTemplate?.displayClass ?? null,
      customApplicantFlagPMOnly: template?.inviteConfig?.applicantFlagTemplate ? (template?.inviteConfig?.applicantFlagTemplate.type === 0) : true,
      autoGeneratedComment: template?.inviteConfig?.autoComment ?? '',
      phase: (template?.phase ?? null)
    },

    transformValues: (values) => ({
      phase: values.phase,
      secondsUntilExpirationDate: values.secondsUntilExpirationDate ? Math.round(values.secondsUntilExpirationDate * secondsInDay) : null,
      daysUntilDisplayDeadline: values.daysUntilDisplayDeadline,
      inviteConfig: {
        header: values.landingPageHeader,
        message: values.landingPageMessage,
        internalNote: values.internalNote,
        expirationDate: formatDateToSource(formatDateFromSource(template?.inviteConfig?.expirationDate ?? null)),
        style: values.landingPageStyle,
        thankYouStyle: values.thankYouPageStyle,
        linkPrefix: values.linkPrefix,
        emailOnSubmit: values.emailOnSubmit,
        autoComment: values.autoGeneratedComment,
        collectDemographics: formatNullableBooleanChoiceToSource(values.collectDemographics),
        proctored: formatNullableBooleanChoiceToSource(values.proctored)
      }
    }),

    validate: {
      daysUntilDisplayDeadline: (value) => (
        value
      )
        ? null
        : 'Must set number of days until the display deadline.',
      secondsUntilExpirationDate: (value, values) => (
        !value || !values.daysUntilDisplayDeadline || (value >= values.daysUntilDisplayDeadline)
      )
        ? null
        : 'Expiration date must be longer than the display deadline.',
      customApplicantFlagDescription: (value, values) => (
        !values.customApplicantFlagName || !!value
      )
        ? null
        : 'Flag description must be set if creating a custom flag.',
      customApplicantFlagIcon: (value, values) => (
        !values.customApplicantFlagName || !!value
      )
        ? null
        : 'Flag icon must be set if creating a custom flag.',
      phase: (value) => (
        value
      )
        ? null
        : 'Must select phase for invite.'
    }
  })
  const previewParams = { title: form.values.landingPageHeader, message: form.values.landingPageMessage, style: form.values.landingPageStyle, locked: false }

  return (
        <Box miw='100%'>
            <Center>
                <Box miw='35rem' maw='35rem'>
                    <Stack>
                        <Title ta='center'>
                            Create Invite Template
                        </Title>
                        <Center>
                            <PreviewButton cycleId={cycles.length ? cycles[0].toString() : null} { ...previewParams } />
                        </Center>
                        <PhaseSelection cycles={cycles} form={form} />
                        <InviteMetadataEditor
                            form={form}
                            hasSourceData={!!template}
                            isAdmin={!!account?.access?.ADMIN}
                        />
                        <Paper shadow='none' withBorder={false} style={{ position: 'sticky', bottom: '1rem', boxShadow: 'none' }}>
                            <Group justify='center' mt='md' grow>
                                <Button
                                    color='green.6'
                                    loading={processing}
                                    onClick={() => {
                                      console.debug('Calling on config template editor submit', { form, cycles })
                                      if (!form.validate().hasErrors) {
                                        onSubmit(form.getTransformedValues())
                                        console.debug('No form errors - called parent submit.')
                                      } else {
                                        console.warn('Not submitting due to validation errors.', form.validate())
                                      }
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    color='gray.6'
                                    onClick={() => {
                                      onCancel()
                                    }}
                                >
                                    Discard
                                </Button>
                            </Group>
                        </Paper>
                    </Stack>
                </Box>
            </Center>
        </Box>
  )
}

function InviteMetadataEditor ({ form, hasSourceData = false, isAdmin = false }) {
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  const updateFormDescription = useCallback((value) => {
    formRef.current.setValues({ internalNote: value })
  }, [])

  const updateFormHeader = useCallback((value) => {
    formRef.current.setValues({ landingPageHeader: value })
  }, [])

  const updateFormMessage = useCallback((value) => {
    formRef.current.setValues({ landingPageMessage: value })
  }, [])

  const updateFormComment = useCallback((value) => {
    formRef.current.setValues({ autoGeneratedComment: value })
  }, [])

  const descriptionProps = useDebouncedField(form.values.internalNote, updateFormDescription)
  const headerProps = useDebouncedField(form.values.landingPageHeader, updateFormHeader)
  const messageProps = useDebouncedField(form.values.landingPageMessage, updateFormMessage)
  const commentProps = useDebouncedField(form.values.autoGeneratedComment, updateFormComment)

  return (
        <Stack justify='flex-start'>
            <DeadlineEditor form={form} />
            <TextInput
                label='Internal Note'
                description='Optional - never displayed to the candidate'
                placeholder='Previous invite expired - sent 05/07'
                { ...form.getInputProps('internalNote') }
                { ...descriptionProps }
            />
            <TextInput
                label='Landing Page Header'
                description='Custom greeting header shown to the candidate when this link is used'
                placeholder='Optional - default is "Open Opportunity"'
                { ...form.getInputProps('landingPageHeader') }
                { ...headerProps }
            />
            <TextInput
                label='Landing Page Message'
                description='Custom greeting message shown to the candidate below the header'
                placeholder='Optional - default is header only, no message'
                { ...form.getInputProps('landingPageMessage') }
                { ...messageProps }
            />
            <LinkPrefixEditor form={form} />
            <Select
                label='Landing Page Style'
                searchable
                data={ inviteStyleOptions }
                { ...form.getInputProps('landingPageStyle') }
            />
            <ComingSoonTooltip>
                <Select
                    label='Thank You Page Style'
                    description='Page displayed to candidate after completing all assessments in phase'
                    searchable
                    data={ thankYouStyleOptions }
                    { ...form.getInputProps('thankYouPageStyle') }
                    disabled={true}
                />
            </ComingSoonTooltip>
            {!!isAdmin && (
                <>
                    <OverrideDefaultFieldInput
                        form={form}
                        field='proctored'
                        label='Proctor Assessments'
                        description='Require ProctorFree running while taking all assessments in phase'
                        toggleLabel='proctoring assessments'
                        hasData={hasSourceData}
                    />
                    <OverrideDefaultFieldInput
                        form={form}
                        field='collectDemographics'
                        label='Collect Demographics'
                        description='Request demographics from candidate upon completing all assessments in phase'
                        toggleLabel='collecting demographics'
                        hasData={hasSourceData}
                    />
                </>
            )}
            <Checkbox
                label='Email on Submit'
                description='Email candidate upon completing all assessments in phase'
                { ...form.getInputProps('emailOnSubmit') }
                checked={form.values.emailOnSubmit}
            />
            <ComingSoonTooltip>
                <TextInput
                    label='Auto Comment'
                    description='Added to candidate upon using the link'
                    placeholder='Optional'
                    { ...form.getInputProps('autoGeneratedComment') }
                    { ...commentProps }
                    disabled={true}
                />
            </ComingSoonTooltip>
        </Stack>
  )
}

const secondsInDay = 86400 // 60 * 60 * 24

function DeadlineEditor ({ form }) {
  const daysUntilExpirationDate = form.values.secondsUntilExpirationDate
  const displayDeadlineDays = form.values.daysUntilDisplayDeadline

  console.debug('DeadlineEditor updating', { daysUntilExpirationDate, displayDeadlineDays, form })

  return (
        <>
            <NumberInput
                label='Display Deadline'
                withAsterisk
                description='Number of days communicated to candidate for assessment completion'
                allowDecimal={false}
                allowNegative={false}
                min={1}
                suffix={(form.values.daysUntilDisplayDeadline === 1) ? ' day' : ' days'}
                {...form.getInputProps('daysUntilDisplayDeadline')}
                onChange={(newValue) => {
                  form.setFieldValue('daysUntilDisplayDeadline', newValue || null)
                  if (newValue && daysUntilExpirationDate && (daysUntilExpirationDate < newValue)) {
                    form.setFieldValue('secondsUntilExpirationDate', newValue)
                  }
                }}
            />
            <NumberInput
                label='Expiration Date'
                description='Number of days until invite is locked after sending (never shown to candidate)'
                allowNegative={false}
                min={displayDeadlineDays || 1}
                suffix={(daysUntilExpirationDate === 1) ? ' day' : ' days'}
                {...form.getInputProps('secondsUntilExpirationDate')}
                value={daysUntilExpirationDate}
                onChange={(newValue) => {
                  form.setFieldValue('secondsUntilExpirationDate', newValue ? (!displayDeadlineDays || (newValue >= displayDeadlineDays) ? newValue : displayDeadlineDays) : null)
                }}
            />
        </>
  )
}
