/* eslint-disable react/prop-types */
import { Select } from '@mantine/core'
import React, { useContext, useMemo } from 'react'
import { ContactDispatchContext } from './ContactContexts';
import { ContactStateUpdate } from './ContactState';

export default function TemplateSelection ({ messageId, messageTemplate, form, index, templates }) {
  const dispatch = useContext(ContactDispatchContext)
  const templateOptions = useMemo(() => {
    console.debug('TemplateSelection options updating', { templates })
    return templates.map(template => ({ value: template.id.toString(), label: template.title, disabled: !!template.disabled }))
  }, [templates])

  console.debug('TemplateSelection component updating', { templateOptions })
  return (
    <Select
      label='Template'
      description='Choose a template to start with'
      data={templateOptions}
      placeholder='Blank'
      clearable
      searchable
      {...form.getInputProps(`messages.${index}.template`)}
      value={messageTemplate || null}
      onChange={value => {
        dispatch({ type: ContactStateUpdate.SetMessageTemplate, messageId: messageId, value: value })
      }}
      nothingFoundMessage='No templates available'
      aria-label='Click to select message template'
    />
  )
}
